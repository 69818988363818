import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Head from "../components/head"
import Img from "gatsby-image"

const ProductPage = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulProducts ( sort: { fields: dateAdded, order: DESC } ) {
                edges {
                    node {
                        title
                        slug
                        productImage {
                          fluid {
                            ...GatsbyContentfulFluid
                          }
                        }
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO />
            <Head title="Products"/>
            <h1>Products</h1>
            <hr />
            <br />
            <ol className="products">
                {data.allContentfulProducts.edges.map((edge, i) => {
                    return (
                        <li className="product">
                            <Link to={`/products/${edge.node.slug}`}>
                                <table>
                                    <tr>
                                        <td>
                                            <Img key={i} fluid={edge.node.productImage.fluid} alt={edge.node.slug} className="productAttr" />
                                        </td>
                                        <td>
                                            <h2>{edge.node.title}</h2>
                                        </td>
                                    </tr>
                                </table>
                            </Link>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}

export default ProductPage